<template>
    <v-bottom-navigation :value="true"
                         v-model="navSelection"
                         :class="{'dark-mode':$store.state.NightMode.topNav == 'dark-mode','light-mode':$store.state.NightMode.topNav == 'light-mode'}"
                         fixed height="50">
        <v-btn text value="home" @click="$router.push({ path: '/dashboard/MainNav' })">
            <span>Menu</span>
            <v-badge color="green" :content="mainMenuBadge" :value="mainMenuBadge" overlap>
                <v-icon>fas fa-bars</v-icon>
            </v-badge>
        </v-btn>
        <!--<v-btn text color="teal" value="extension" @click="$router.push({ path: '/dashboard/Extensions' })">
      <span>Extensions</span>
      <v-badge color="red" :content="extensionsBadge" :value="extensionsBadge" overlap>
        <v-icon>extension</v-icon>
      </v-badge>
    </v-btn>-->
        <!--<v-btn text color="teal" value="budgetdata" @click="$router.push({ path: '/dashboard/BudgetData' })">
      <span>Budget Data</span>
      <v-badge color="red" :content="budgetDataBadge" :value="budgetDataBadge" overlap>
        <v-icon>assessment</v-icon>
      </v-badge>
    </v-btn>-->
        <!--<v-btn text color="teal" value="reminder" @click="$router.push({ path: '/dashboard/Reminders' })">
      <span> Reminder </span>
      <v-badge color="red" :content="notificationBadge" :value="notificationBadge" overlap>
        <v-icon>alarm</v-icon>
      </v-badge>
    </v-btn>-->
    </v-bottom-navigation>
</template>

<script>
  export default {
    data() {
      return {
        mainMenuBadge: '',
        extensionsBadge: '3',
        budgetDataBadge: '1',
        notificationBadge: '2',

        navSelection: ''
      }
    }
  }
</script>

<style scoped>
  .dark-mode {
    background-color: #212121;
  }
</style>

